<template>
	<div class="theme-dashboard-view">
		<h1>{{ $t("inventory_search_title") }}</h1>
		<!-- Search Menu -->
		<div class="my-3 p-3 shadow-sm border">
			<div class="d-flex w-100">
				<span class="mx-2 p-2 rounded d-flex align-items-center col-2"
					:class="{ border: ShowFilters, 'bg-light': ShowFilters }" @click="
						() => {
							ShowFilters = !ShowFilters;
						}
					" style="cursor: pointer">
					<i class="fa fa-filter" aria-hidden="true"></i>
					<span class="mx-2" style="width: 15vh">{{
						$t("inventory_search_bar_filter_button")
					}}</span>
				</span>
				<div class="col-10 d-flex align-items-center px-2">
					<div class="col-md-8 col-sm-12 border d-flex">
						<input type="search" placeholder="Search" style="border: none" class="p-2 w-100"
							v-model="queryText" @keydown.enter="SearchProduct" />
						<select class="form-group" style="border: none" v-model="searchType">
							<option value="product">{{ $t("inventory_search_product_text") }}</option>
							<option value="category">{{ $t("inventory_search_category_text") }}</option>
						</select>
						<button class="btn border theme-background-primary text-light p-2" @click="SearchProduct">
							{{ $t("inventory_search_bar_button_name") }}
						</button>
					</div>
				</div>
			</div>
			<div class="my-2 d-flex" :class="{ 'd-flex': ShowFilters, 'd-none': !ShowFilters }" style="height: 250px">
				<div class="col-4 p-3">
					<strong>{{ $t("inventory_search_filter_tags") }}</strong>
					<div class="d-flex flex-wrap" style="max-height: 110px; overflow-y: auto">
						<div v-for="(tag, index) in searchTags" :key="index" class="border p-2 mx-2 my-1">
							{{ tag }}
						</div>
					</div>
					<div>
						<input type="text" @keydown.enter="
							() => {
								searchTags.push(tagText);
							}
						" v-model="tagText" />
						<button @click="
							() => {
								searchTags.push(tagText);
							}
						">
							<i class="fa fa-plus" aria-hidden="true"></i>
						</button>
					</div>
				</div>
				<div class="col-4 p-3">
					<strong>{{ $t("inventory_search_filter_price_range") }}</strong>
					<div class="d-flex my-2">
						<label class="col-2">{{ $t("inventory_search_filter_price_range_smallest") }}</label>
						<input class="col-5 p-2" type="Number" placeholder="Smallest Price"
							v-model="searchPriceSmallest" />
					</div>

					<div class="d-flex my-2">
						<label class="col-2">{{ $t("inventory_search_filter_price_range_highest") }}</label>
						<input class="col-5 p-2" type="text" placeholder="Highest Price" v-model="searchPriceHighest" />
					</div>
				</div>

				<div class="col-4 p-3">
					<strong>{{ $t("inventory_search_filter_category") }}</strong>
					<div>
						<select v-model="searchCategory" class="form-select">
							<option :value="0" selected>None</option>
							<option :value="category.id" v-for="(category, index) in categoryList" :key="index">
								{{ category.name }}
							</option>
						</select>
					</div>
				</div>
			</div>
		</div>

		<!-- Loading Indicator -->
		<div class="d-flex justify-content-center">
			<LoadingIndicator :active="loading" />
		</div>
		<!-- Loading Error -->
		<div v-show="load_error">
			<div class="justify-content-center text-center text-danger my-4">
				<h4>{{ load_error }}</h4>
			</div>
		</div>

		<!-- <div class="text-danger my-5">
			{{ results }}
		</div> -->
		<!-- RESULTS -->
		<div class="my-4" v-if="Object.keys(results).length != 0">
			<strong>{{ $t("inventory_search_results_text") }}: {{ searchText }}</strong>
			<!-- {{results}} -->
			<!-- RESULT FOR PRODUCTS -->
			<div class="my-3" v-show="results.products">
				<!-- <h3 class="py-4 theme-background-header m-0 text-center">
					{{ $t("inventory_search_product_text") }}
				</h3>
				<hr class="p-0 m-0" /> -->
				<!-- {{ results.products }} -->
				<Table :rows="results.products ?? []" :hover="true"
					:contain="['id', 'name', 'category_name', 'price', 'rating']" :rowClickFunction="clickProduct">
					<template v-slot:id-title>
						<div>{{ $t("inventory_table_product_id") }}</div>
					</template>
					<template v-slot:name-title>
						<div>{{ $t("inventory_table_product_name") }}</div>
					</template>
					<template v-slot:category_name-title>
						<div>{{ $t("inventory_table_product_category") }}</div>
					</template>
					<template v-slot:price-title>
						<div>{{ $t("inventory_table_product_price") }}</div>
					</template>
				</Table>
				<!-- Error message -->
				<div class="py-5 px-3 my-3 text-danger text-center" v-show="results.products?.length == 0">
					<h3>{{ $t("inventory_search_empty_text") }}</h3>
				</div>
				<!-- Page navigator -->
				<div class="d-flex justify-content-center p-2" v-if="!loading && results.products?.length">
					<!-- {{ $t("page_navigator_name") }}:
					<div
						class="mx-3"
						v-for="page in pages"
						:key="page"
						:class="page == current_page ? 'font-weight-bold' : ''"
						style="cursor: pointer"
						@click="getSearchResults(page, 'product')"
					>
						{{ page }}
					</div>
					<div style="cursor: pointer" @click="nextPage">
						<i class="fa fa-arrow-right" aria-hidden="true"></i>
					</div> -->
					<PageNav :pages="pages" :current_page="current_page" @click-page="clickProductPage"
						@next-page="nextPage" @prev-page="prevPage" @goto-last="gotoLast" />
				</div>
			</div>
			<!-- RESULTS FOR CATEGORIES -->
			<div class="my-3" v-show="results.categories">
				<!-- <h3 class="py-3 bg-light m-0 text-center">
					{{ $t("inventory_search_category_text") }}
				</h3> -->
				<!-- {{ results.categories }} -->
				<Table :rows="results.categories ?? []" :hover="true" :contain="['id', 'name', 'description', 'tags']"
					:rowClickFunction="clickCategory">
					<template v-slot:id-title>
						<div>{{ $t("inventory_search_category_id") }}</div>
					</template>
					<template v-slot:name-title>
						<div>{{ $t("inventory_search_category_name") }}</div>
					</template>
					<template v-slot:description-title>
						<div>{{ $t("inventory_search_category_description") }}</div>
					</template>
					<template v-slot:tags-title>
						<div>{{ $t("inventory_search_category_tags") }}</div>
					</template>

					<template v-slot:tags="params">
						<div>
							<span v-for="tag in params.row.tags" :key="tag"
								class="mx-1 p-2 theme-background-secondary rounded">{{ tag }}</span>
						</div>
					</template>
				</Table>
				<div class="py-5 px-3 my-3 text-danger text-center" v-show="results.categories?.length == 0">
					<h3>{{ $t("inventory_search_empty_text") }}</h3>
				</div>
				<!-- Page navigator -->
				<div class="d-flex justify-content-center p-2" v-if="!loading && results.categories?.length">
					<!-- {{ $t("page_navigator_name") }}:
					<div
						class="mx-3"
						v-for="page in pagesTrim(pages, 5)"
						:key="page"
						:class="page == current_page ? 'font-weight-bold' : ''"
						style="cursor: pointer"
						@click="getSearchResults(page, 'category')"
					>
						{{ page }}
					</div>
					<div style="cursor: pointer" @click="nextPage">
						<i class="fa fa-arrow-right" aria-hidden="true"></i>
					</div> -->
					<PageNav :pages="pages" :current_page="current_page" @click-page="clickCategoryPage"
						@next-page="nextPage" @prev-page="prevPage" @goto-last="gotoLast" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// Components
import Table from "@/components/Table.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PageNav from "@/components/PageNav.vue";
// import ProductSearch from "@/components/ProductSearch.vue";
// Services
import SearchService from "@/services/search.js";
import CategoryService from "@/services/category.js";
export default {
	name: "Search",
	components: {
		Table,
		LoadingIndicator,
		PageNav,
		// ProductSearch,
	},
	data() {
		return {
			// Display
			loading: false,
			load_error: "",
			ShowFilters: false,
			categoryList: [],
			tagText: "",

			// Search Options
			queryText: "",
			searchText: "",
			searchType: "product",
			searchTags: [],
			searchPriceSmallest: 0,
			searchPriceHighest: 0,
			searchCategory: 0,

			// Results
			results: {},

			// To help with animation
			product_list: [],
			category_list: [],

			current_page: 1,
			pages: 0,
			category_functions: [
				{
					text: "Update",
				},
				{
					text: "Delete",
				},
			],
		};
	},

	methods: {
		clickProduct(index) {
			this.$router.push(
				"/inventory/product/" + this.results.products[index].id
			);
		},
		clickCategory(index) {
			console.log("Click Category:", index);
			this.$router.push(
				"/category/update/" + this.results.categories[index].id
			);
		},
		// Get search products
		getSearchResults(page, searchType) {
			this.loading = true;
			this.load_error = "";
			this.results = {};
			this.searchText = this.queryText;
			SearchService.SearchProduct(
				page,
				{
					search: this.queryText,
					category_id: this.searchCategory !== 0 ? this.searchCategory : null,
					tags: this.searchTags,
					price_g: this.searchPriceHighest,
					price_l: this.searchPriceSmallest,
				},
				searchType
			).then(
				(response) => {
					this.loading = false;
					console.log(response, searchType);
					this.results = response.data.data;
					this.product_list = this.results.products;
					this.category_list = this.results.categories;

					this.pages = response.data.data.total_pages;
					this.current_page = response.data.data.next_page_num - 1;
				},
				(error) => {
					this.loading = false;
					console.log("Error:", error);
					this.load_error =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
		// Search for product
		SearchProduct() {
			this.current_page = 1;
			this.getSearchResults(1, this.searchType);
		},
		nextPage() {
			if (this.current_page !== this.pages) {
				this.getSearchResults(this.current_page + 1, this.searchType);
			}
		},
		prevPage() {
			if (this.current_page !== 1) {
				this.getSearchResults(this.current_page - 1, this.searchType);
			}
		},
		gotoLast() {
			console.log("Goto last");
			if (this.current_page !== this.pages) {
				this.getSearchResults(this.pages, this.searchType);
			}
		},
		clickCategoryPage(page) {
			this.getSearchResults(page, "category");
		},
		clickProductPage(page) {
			this.getSearchResults(page, "product");
		},
	},
	mounted() {
		if (this.$route.params.query_text) {
			this.queryText = this.$route.params.query_text;
			this.searchType = this.$route.params.query_type;
			this.getSearchResults(1, this.$route.params.query_type);
		}
		// Get the categories for the product
		CategoryService.getCategories(1).then((response) => {
			this.categoryList = response.data.data.categories;
		});
	},
};
</script>

<style>
.font-weight-bold {
	font-weight: 900;
}
</style>
